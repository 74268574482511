<template>
	<div :style="{ marginBottom: '15px' }">
		<div @click="active = !active">
			<span class="form-label">{{ label }} <i :class="active ? 'i-chevron-down' : 'i-chevron-right'"></i></span>
		</div>
		<div :class="active ? 'dropdown-data dropdown-data--active' : 'dropdown-data'">
			<ul class="word-list">
				<li v-for="(word, index) in key_words" :key="index">
					{{ word }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		key_words: Array,
		label: String,
	},
	data() {
		return {
			active: false,
		}
	}
}
</script>

<style lang="scss" scoped>
	.dropdown-data {
		height: 0;
		visibility: hidden;

		&--active {
			height: auto;
			visibility: visible;
		}
	}

	.word-list {
		li { 
			display: inline-block;
			background: rgb(233, 233, 233);
			border-radius: 4px;
			padding: 4px 8px;
			margin-right: 8px;
			margin-top: 4px;
		}
	}
</style>