<template>
    <div>
        <mega-modal ref="modal-2" class="modal-sm"
                    :active="modal"
                    @onClose="close">

            <div v-if="modal">

                <mega-header :title="`${ this.$t('ref_edit_title') } #${ this.refImage.idt_ref_image }`" class="bg-lighter">
                    <span class="text-muted">{{ $t('ref_sub_title') }}</span>
                </mega-header>

                <div class="row p-3">
                    <div class="sm-down:col-12 md-up:col-6">
                        <div class="card-body">
                            <mega-drop-zone :class="{ 'await loading': img_loading  }" @change="renderFile" :type="['image/jpeg', 'image/png']">
                                <mega-image class="image bg-contain" :style="{ backgroundSize: 'contain' }" ratio="16x9" :src="preview"/>
                            </mega-drop-zone>
                            <a v-if="is_exist" :href="existUrl" target="_blank" class="btn btn-danger w-100 mt-2">{{ $t('ref_exist_btn') }} ID: {{ existId }}</a>
                            <mega-button class="btn btn-light w-100 mt-2" @click="openPreview"><i class="i-zoom-in" :style="{ fontSize: '24px' }"></i></mega-button>
                        </div>

                        <div class="card-body">

                            <mega-input :label="$t('new_model_name')" v-model="refImage.name"/>

                            <mega-textarea :label="$t('desc')" v-model="refImage.description"/>

                        </div>
                    </div>

                    <div class="sm-down:col-12 md-up:col-6">
                        <div class="card-body">

                            <Dropdown :label="$t('ref_ocr')" v-if="refImage.ocr_strings" :key_words="refImage.ocr_strings" />

                            <mega-input :label="$t('ref_key_words')" v-model="ref_words"/>

                            <mega-range class="bg-primary mb-0" :label="$t('ref_pic_width')" v-model="refImage.width" :min="10" :max="1000" :step="5"/>
                            <div class="d-flex justify-content-between text-muted">
                                <small>10</small>
                                <small>1000</small>
                            </div>

                            <mega-number :min="10" :max="1000" :step="5" v-model="refImage.width" :help="refImage.width+'cm'"/>

                            <mega-switch class="w-100 mt-3 bg-success" :label="$t('ref_global_loc')"
                                        v-model="refImage.is_global"/>

                            <mega-switch class="w-100 mt-3 bg-success" :label="$t('ref_sticky')"
                                        v-model="refImage.sticky"/>
                        </div>
                    </div>

                    <div class="card-body pt-0 col-12">

                        <map-widget 
                            ratio="16x9"
                            get-location
                            v-if="!refImage['is_global'] && refImage.lat"
                            :images="ref_images"
                            @update="updatePosition"
                            :is-global="refImage.is_global"
                            :lat="refImage.lat || 0"
                            :lng="refImage.lng || 0"/>
                        
                    </div>

                    <div class="card-body col-12">
                        <div class="navbar">

                            <mega-button class="btn-danger btn-sm mr-auto" @click="delete_image">{{ $t('del') }}</mega-button>

                            <div class="navbar-group ml-auto">
                                <mega-button class="btn-white btn-sm mr-2" @click="close">{{ $t('close') }}</mega-button>
                                <mega-button class="btn-success btn-sm" :disabled="!passed" :loading="img_loading" @click="submit">{{ $t('save') }}</mega-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mega-modal>

        <ImagePreview ref="preview" :src="preview" ratio="1x1"/>

        <delete-confirm ref="delete-confirm"
                        :title="$t('ref_del')"
                        @confirm="deleteImage"
                        :text="`${ this.$t('ref_del_confirm') }: ${ refImage.name }`"/>
    </div>
</template>

<script>
    import axios from 'axios'

    import DeleteConfirm from './delete-confirm'
    import ImagePreview from './image-preview'
    import MapWidget from './map'
    import RoundTypes from '../../data/round_types.json';
    import Dropdown from '../../components/Dropdown'

    export default {
        data() {
            return {
                refImage: {},
                ref_words: '',
                modal: false,
                preview: undefined,
                file: undefined,
                img_loading: false,
                is_exist: false,
                existId: null,
                existUrl: null,
            }
        },
        computed: {
            ref_images() {

                let images = this.$parent.ref_images || [];

                return images.reduce((result, img) => {

                    let position = {lng: img.lng, lat: img.lat};

                    if (this.refImage.idt_ref_image !== img.idt_ref_image)
                        result.push({position, is_global: img.is_global});

                    return result;
                }, []);
            },

            passed() {
                return this.refImage.width && this.refImage.match_percent && this.refImage.name
            },
        },
        methods: {

            submit() {
                this.img_loading = true;
                this.is_exist = false;

                let formData = new FormData();
                formData.append("idt_ref_image", this.refImage.idt_ref_image);
                formData.append("name", this.refImage.name);
                formData.append("description", this.refImage.description);
                formData.append("width", this.refImage.width);
                // formData.append("match_percent", this.refImage.match_percent);
                // formData.append("match_percent", 0);
                formData.append("is_global", this.refImage['is_global']);
                formData.append("sticky", this.refImage['sticky']);

                formData.append("lng", this.refImage.lng);
                formData.append("lat", this.refImage.lat);

                let word_array = [];
                if (this.ref_words) {
                    this.ref_words = this.ref_words.replace(/\s/g, "");
                    word_array = this.ref_words.split(',');
                }

                formData.append("ref_strings", JSON.stringify(word_array));

                if(this.file) formData.append("image", this.file);

                this.$api.v2.put('/user/refImage', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(() => { this.close(); this.$alert.success(this.$t('ref_edit_msg')); })
                    .catch((err) => {
                        const data = err.response.data;
						if (err.response.status === 400) {
                            switch (data.code) {
                                case 2:
                                    this.$alert.danger(this.$t('err_ref_chars'));
                                    break;
                                case 4:
                                    this.$alert.danger(this.$t('err_ref_small'));
                                    break;
                                case 5:
                                    this.$alert.danger(this.$t('err_ref_quality'));
                                    break;
                                case 6:
                                    if (data.details.matched_arp_id !== 0) {
                                        if (this.$store.state.arps.filter(arp => arp.idt_arp === data.details.matched_arp_id).length) {
                                            this.getRouteName(data.details.matched_ref.idt_model)
                                            .then(name => {
                                                this.existUrl = `/arr/${ data.details.matched_arp_id }/${ name }/${ data.details.matched_ref.idt_model }/${ data.details.matched_ref.idt_model_modif }`;
                                                this.existId = data.details.matched_ref.idt_ref_image;
                                                this.is_exist = true;
                                            });
                                        }
                                    }

                                    this.$alert.danger(this.$t('err_ref_match'));
                                    break;
                            }
						} else {
							this.$alert.danger(this.$t('err_server'));
                        }
                    })
                    .finally(() => { this.img_loading = false; })
            },

            delete_image() { this.$refs['delete-confirm'].open() },
            deleteImage(modal) {

                this.$api.v2.delete('/user/refImage', {params: {idt_ref_image: this.refImage.idt_ref_image}})
                    .then(() => {
                        this.close();
                        modal.close();
                        this.$alert.success('Image deleted');
                    })
                    .catch(() => { this.$alert.danger('Oops.. Server error') })
            },

            openPreview() { this.$refs.preview.open(this.preview) },

            open(id) {
                this.refImage.idt_ref_image = id;
                this.$api.v2.get('/user/refImage', {params: {idt_ref_image: id}})
                    .then(response => response.data['ref_image'])
                    .then(ref_image => {
                        this.refImage = ref_image;
                        this.preview = ref_image['url'];
                        
                        if (ref_image.ref_strings)
                            this.ref_words = ref_image.ref_strings.join(',');

                        document.body.style.overflowY = 'hidden';
                        this.modal = true;
                    })
            },

            close() {
                this.modal = false;
                this.$emit('close');
                document.body.style.overflowY = 'auto';
            },

            renderFile(file) {

                if (!file) {
                    this.$alert.danger(this.$t('err_ref_file'));
                    return;
                }

                this.validateImageSize(file)
                 .then(() => {
                     this.file = file;
 
                     let reader = new FileReader();
                     reader.readAsDataURL(this.file);
                     reader.onloadend = () => {
                         this.preview = reader.result;
                     }
                 })
                 .catch(() => {
                    this.$alert.danger(this.$t('err_ref_size'));
                 });
            },

            updatePosition(location) {
                this.refImage.lat = location.lat;
                this.refImage.lng = location.lng;
            },

            validateImageSize(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        const img = new Image();
                        img.onload = () => {
                            if (img.width < 480 && img.height < 480) {
                                reject();
                            }
 
                            resolve();
                        }   
 
                        img.src = reader.result;
                    }
 
                    reader.readAsDataURL(file);               
                })   
            },

            getRouteName(modelId) {
                return this.$api.v2.get('/user/model', { params: { idt_model: modelId } })
                .then(({ data }) =>  data.idc_round_type)
                .then(type => RoundTypes.filter(item => item.value == type)[0].name)
                .catch(err => { 
                    console.log(err); 
                    this.$alert.danger(this.$t('err_server')) 
                })
            }
        },

        components: {
            DeleteConfirm,
            MapWidget,
            ImagePreview,
            Dropdown
        }
    }
</script>