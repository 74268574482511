var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: { marginBottom: "15px" } }, [
    _c(
      "div",
      {
        on: {
          click: function($event) {
            _vm.active = !_vm.active
          }
        }
      },
      [
        _c("span", { staticClass: "form-label" }, [
          _vm._v(_vm._s(_vm.label) + " "),
          _c("i", { class: _vm.active ? "i-chevron-down" : "i-chevron-right" })
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.active
          ? "dropdown-data dropdown-data--active"
          : "dropdown-data"
      },
      [
        _c(
          "ul",
          { staticClass: "word-list" },
          _vm._l(_vm.key_words, function(word, index) {
            return _c("li", { key: index }, [
              _vm._v("\n\t\t\t\t" + _vm._s(word) + "\n\t\t\t")
            ])
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }